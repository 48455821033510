<template>
  <div>
    <v-list v-if="stockEntries !== null && stockEntries !== undefined && stockEntries.length > 0">
      <template v-for="(entry, index) in stockEntries">
        <StockListItem
          :key="index"
          :entry="entry"
        />
        <v-divider
          v-if="stockEntries.length - 1 > index"
          :key="index + 'div'"
        />
      </template>
    </v-list>
  </div>
</template>

<script>

import StockListItem from './StockListItem'

export default {
    name: 'StockList',
    props: ['stockEntries'],
    components: { StockListItem }
}
</script>