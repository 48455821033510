<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{ entry.timestamp | moment('DD.MM.YYYY') }}</v-list-item-title>
      <v-list-item-subtitle>{{ entry.note }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title class="red--text text-right">
        {{ entry.unitOfChange }} {{ entry.unitOfMeasure }}
      </v-list-item-title>
      <v-list-item-subtitle>{{ entry.note }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content class="text-right green--text">
      <v-list-item-title>{{ entry.newAmount }} {{ entry.unitOfMeasure }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
    name: 'StockListItem',
    props: ['entry']
}
</script>